<template>
  <v-list-item
    class="macrotrend"
    :to="target"
    :style="styles"
    :key="macrotrend.id"
    :ripple="false"
    xno-action="done"
  >
    <v-list-item-content>
      <v-list-item-title>
        <div>
          {{ macrotrend.title }}
          <span class="float-right v-list-item__subtitle">{{ status }}</span>
        </div>
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class MacrotrendEvaluation extends Vue {
  @Prop({ type: Object, required: true }) macrotrend

  get done () {
    return this.macrotrend.evaluations !== null
  }

  get styles () {
    return {
      color: 'white',
      'background-color': 'inherit'
    }
  }

  get icon () {
    return this.done
      ? 'mdi-check'
      : 'mdi-arrow-right'
  }

  get status () {
    return this.done
      ? this.$t('complete')
      : this.$t('incomplete')
  }

  get target () {
    return {
      name: 'evaluate',
      params: {
        macrotrendId: this.macrotrend.id,
        megatrendId: this.macrotrend.megatrend.id
      }
    }
  }
}
</script>

<i18n>
{
  "de": {
    "complete": " ",
    "incomplete": "Jetzt bewerten"
  },
  "en": {
    "complete": " ",
    "incomplete": "Evaluate now"
  }
}
</i18n>
