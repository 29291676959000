<template>
  <v-card
    v-if="loaded"
    flat
    class="overview-intro"
  >
    <v-card-title class="px-0 pb-3">
      <div>
        <div class="caption">{{ $t('caption') }}</div>
        <h1>{{ title }}</h1>
      </div>
    </v-card-title>

    <v-divider />
    <v-card-text
      class="px-0"
      v-html="description"
    />
  </v-card>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { DESCRIPTION, LOADED, TITLE } from '@/store/getter-types'

@Component
export default class OverviewIntro extends Vue {
  @Getter(LOADED) loaded
  @Getter(TITLE) title
  @Getter(DESCRIPTION) description
}
</script>

<i18n>
{
  "de": {
    "caption": "Trendbewertung"
  },
  "en": {
    "caption": "Trend evaluation"
  }
}
</i18n>
