<template>
  <v-list-item two-line>
    <v-list-item-avatar
      class="pt-1"
      size="50"
      color="primary"
    >
      <v-img :src="user.image" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="body-1">{{ user.name }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="user.email">
      <v-btn
        :href="`mailto:${user.email}`"
        icon
      >
        <v-icon color="primary">mdi-email</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import { USER } from '../../model/types'

@Component
export default class ContactPerson extends Vue {
  @Prop({ type: Object, validate: (obj) => obj.__typename === USER }) user
}
</script>

<style lang="sass" scoped>
  @import "../../styles/font"
  .v-avatar
    border: 2px solid

  .v-list-item__action
    width: 50px

  .v-list-item__title.body-1
    font-family: $font-family-bold !important
</style>
