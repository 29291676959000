<template>
  <v-card flat>
    <v-card-title>{{ $t('title') }}</v-card-title>
    <contact-person
      v-for="user in contacts"
      :key="user.id"
      :user="user"
    />
  </v-card>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import ContactPerson from './person'
import { Getter } from 'vuex-class'
import { CONTACTS } from '@/store/getter-types'

@Component({
  components: {
    ContactPerson
  }
})
export default class ContactPersons extends Vue {
  @Getter(CONTACTS) contacts
}
</script>

<i18n>
{
  "de": {
    "title": "Ansprechpartner"
  },
  "en": {
    "title": "Contact persons"
  }
}
</i18n>
