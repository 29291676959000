<template>
  <v-dialog
    v-model="showFeedbackDialog"
    width="600px"
  >
    <form
      @submit.prevent="onSubmit"
    >
      <v-card>
        <v-card-title>
          <div>
            <h2>{{ $t('title') }}</h2>
          </div>
          <v-spacer/>
          <v-btn
            @click="showFeedbackDialog = false"
            :disabled="pending"
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-html="feedbackIntro"></div>
        </v-card-text>
        <v-card-text>
          <v-textarea
            v-model="feedback"
            :error-messages="errorMessage"
            :label="$t('label')"
            filled
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="pending"
            @click="showFeedbackDialog = false"
            color="red"
            text
          >
            {{ $t('close') }}
          </v-btn>
          <v-spacer/>
          <v-btn
            type="submit"
            color="primary"
            :disabled="disabled"
            :loading="pending"
            depressed
          >
            {{ $t('submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<i18n>
{
  "de": {
    "title": "Feedback",
    "submit": "Senden",
    "close": "Abbrechen",
    "label": "Feedback",
    "error": "Bitte geben Sie Ihr Feedback ein"
  },
  "en": {
    "title": "Feedback",
    "submit": "Submit",
    "close": "Cancel",
    "label": "Feedback",
    "error": "Please enter your feedback"
  }
}
</i18n>

<script>
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator'

@Component
export default class FeedbackDialog extends Vue {
  @VModel({ type: Boolean }) showFeedbackDialog

  @Prop({ type: String, required: true }) feedbackIntro

  @Prop({ type: Boolean, default: () => false }) pending

  feedback = ''
  dirty = false

  @Watch('feedback')
  onFeedbackChanged () {
    this.dirty = true
  }

  get error () {
    return this.feedback === '' && this.dirty
  }

  get disabled () {
    return this.feedback === '' || this.pending
  }

  get errorMessage () {
    if (this.error) {
      return this.$t('error')
    }

    return ''
  }

  onSubmit () {
    this.$emit('submit', this.feedback)
  }
}
</script>
