<template>
  <v-list-group
    class="megatrend-evaluation"
    dark
    :style="styles"
    :ripple="false"
    :value="megatrend.id === open"
  >
    <v-list-item
      dark
      :ripple="false"
      slot="activator"
      class="megatrend"
    >
      <v-list-item-content>
        <v-list-item-title>
          <div>
            {{ megatrend.title }}
            <span class="v-list-item__subtitle float-right">{{ status }}</span>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <macrotrend-evaluation
      v-for="macrotrend in macrotrends"
      :macrotrend="macrotrend"
      :key="macrotrend.id"
    />
  </v-list-group>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

import MacrotrendEvaluation from './macrotrend'

@Component({
  components: {
    MacrotrendEvaluation
  }
})
export default class MegatrendEvaluation extends Vue {
  @Prop({ type: Object, required: true }) megatrend
  @Prop({ type: Array, required: true }) macrotrends
  @Prop({ type: String, default: () => null }) open

  get styles () {
    return {
      'background-color': this.megatrend.color,
      color: 'white'
    }
  }

  get status () {
    let todo = 0
    this.macrotrends.forEach(elem => {
      todo += elem.evaluations === null ? 1 : 0
    })
    return this.$tc('status', todo, { cnt: todo })
  }
}
</script>

<style lang="sass"
       scoped>
  .v-list-item.theme--dark.megatrend
    padding-left: 0
    padding-right: 0
</style>

<i18n>
{
  "de": {
    "status": "Alle Macro-Trends bewertet|{cnt} Macro-Trend zu bewerten|{cnt} Macro-Trends zu bewerten"
  },
  "en": {
    "status": "All macro-trends rated|Rate {cnt} macro-trend|Rate {cnt} macro-trends"
  }
}
</i18n>
