<template>
  <v-container>
    <v-row>
      <v-col
        sm="12"
        md="8"
      >
        <div v-if="loaded">
          <overview-intro/>
          <v-card flat>
            <v-card-text>
              <evaluations-index
                :macrotrends="macrotrends"
                :open="megatrendId"
              />
            </v-card-text>
          </v-card>
          <overview-progress/>
        </div>
      </v-col>
      <v-col
        sm="12"
        md="4"
      >
        <contact-persons/>
      </v-col>
    </v-row>
    <feedback-dialog
      @submit="onSubmit"
      v-model="showFeedbackDialog"
      :feedback-intro="feedbackIntro"
      :pending="pending"
    />
  </v-container>
</template>

<i18n>
{
  "de": {
    "success": "Feedback erfolgreich gesendet.",
    "error": "Beim Senden des Feedbacks ist ein Fehler aufgetreten."
  },
  "en": {
    "success": "Feedback successfully submitted.",
    "error": "An error occured while submitting the feedback."
  }
}
</i18n>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import {
  COMPLETED,
  FEEDBACK_ENABLED,
  FEEDBACK_INTRO,
  IS_PERSONALIZED_EVALUATION,
  LOADED,
  MACROTRENDS
} from '../store/getter-types'

import ContactPersons from '../components/contact-persons'
import EvaluationsIndex from '../components/evaluations'
import OverviewIntro from '../components/overview/intro'
import OverviewProgress from '../components/overview/progress'
import FeedbackDialog from '@/components/overview/feedback-dialog.vue'
import { PUBLIC_EVALUATION_SEND_FEEDBACK } from '@/store/action-types'
import { SHOW_SNACKBAR } from '@/store/mutation-types'

@Component({
  components: {
    FeedbackDialog,
    ContactPersons,
    EvaluationsIndex,
    OverviewIntro,
    OverviewProgress
  }
})
export default class OverviewView extends Vue {
  @Prop({
    type: String,
    required: true
  }) locale

  @Prop({
    type: String,
    required: true
  }) publicEvaluationId

  @Prop({
    type: String,
    default: () => null
  }) megatrendId

  @Action(PUBLIC_EVALUATION_SEND_FEEDBACK) sendFeedback

  @Getter(LOADED) loaded
  @Getter(MACROTRENDS) macrotrends
  @Getter(COMPLETED) completed
  @Getter(FEEDBACK_INTRO) feedbackIntro
  @Getter(FEEDBACK_ENABLED) feedbackEnabled
  @Getter(IS_PERSONALIZED_EVALUATION) isPersonalizedEvaluation

  @Mutation(SHOW_SNACKBAR) showSnackbar

  showFeedbackDialog = false
  pending = false

  @Watch('completed', { immediate: true })
  onCompletedChange (newVal) {
    this.showFeedbackDialog = newVal && !this.isPersonalizedEvaluation && this.feedbackEnabled
  }

  async onSubmit (feedback) {
    try {
      this.pending = true
      await this.sendFeedback({
        publicEvaluationId: this.publicEvaluationId,
        feedback
      })
      this.showSnackbar({
        message: this.$t('success'),
        color: 'success'
      })
      this.showFeedbackDialog = false
    } catch (error) {
      console.log('%cError on sending feedback: ', 'color:red', error) // eslint-disable-line no-console
      this.showSnackbar({
        message: this.$t('error'),
        color: 'error'
      })
    } finally {
      this.pending = false
    }
  }
}
</script>
