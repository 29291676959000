<template>
  <v-card flat>
    <v-card-text>
      <v-row
        v-if="completed"
      >
        <v-col
          sm="12"
          md="8"
        >
          <h3>{{ $t('thanks.title') }}</h3>
          <div>
            {{ $t('thanks.content') }}
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          sm="12"
          md="4"
        >
          {{ $t('progress') }}: {{ progress }}
        </v-col>
        <v-col
          sm="12"
          md="8"
        >
          <v-progress-linear
            class="mt-1"
            height="10"
            color="primary"
            :value="value*100"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { COMPLETED, PROGRESS, PROGRESS_FORMATTED } from '../../store/getter-types'

@Component
export default class OverviewProgress extends Vue {
  @Getter(PROGRESS) value
  @Getter(PROGRESS_FORMATTED) progress
  @Getter(COMPLETED) completed
}
</script>

<i18n>
{
  "de": {
    "progress": "Bewertungsfortschritt",
    "thanks": {
      "title": "Vielen Dank!",
      "content": "Ihre Bewertung ist vollständig."
    }
  },
  "en": {
    "progress": "Evaluation progress",
    "thanks": {
      "title": "Thank you!",
      "content": "Your evaluation is complete."
    }
  }
}
</i18n>
