<template>
  <v-list
    class="evaluation-tree"
    expand
    dark
    :ripple="false"
    two-line
  >
    <megatrend-evaluation
      v-for="megatrend in megatrends"
      :key="megatrend.id"
      :megatrend="megatrend"
      :open="open"
      :macrotrends="macrotrendsOfMegatrend(megatrend)"
    />
  </v-list>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import MegatrendEvaluation from './megatrend'

@Component({
  components: {
    MegatrendEvaluation
  }
})
export default class EvaluationsIndex extends Vue {
  @Prop({ type: Array, required: true }) macrotrends
  @Prop({ type: String, default: () => null }) open

  get megatrends () {
    const mts = {}
    this.macrotrends.forEach(elem => {
      mts[elem.megatrend.id] = elem.megatrend
    })

    return Object.values(mts)
  }

  macrotrendsOfMegatrend (megatrend) {
    return this.macrotrends.filter(elem => elem.megatrend.id === megatrend.id)
  }
}
</script>
